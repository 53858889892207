<template>
  <div class="avater-rq" v-if="!getLocal('token')">
    <el-avatar :icon="UserFilled" />
    <div class="login-btn" @click="wechatLogin">登录 / 注册</div>
  </div>
    <div class="avater-rq" v-else>
      <el-avatar :icon="UserFilled" />
      <div class="user-rq">
        <div class="nickname">{{getLocal('userid')}}</div>
        <div class="user-group">{{ getLocal('group_id') == 1 ? '普通用户' : '会员用户'}}</div>
      </div>
    </div>
  <div class="mycenter-menu-rq">
    <a href="/helpCenter">
      <div class="mycenter-for-data">
        <div class="menu-icon"><i class="iconfont icon-bangzhuzhongxin"></i></div>
        <div class="menu-title">帮助中心</div>
        <div class="menu-go"><i class="iconfont icon-xiangyoussku"></i></div>
      </div>
    </a>
    <a href="/myorder">
      <div class="mycenter-for-data">
        <div class="menu-icon"><i class="iconfont icon-dingdan1"></i></div>
        <div class="menu-title">我的订单</div>
        <div class="menu-go"><i class="iconfont icon-xiangyoussku"></i></div>
      </div>
    </a>
    <a href="/myinvoice">
      <div class="mycenter-for-data">
        <div class="menu-icon"><i class="iconfont icon-shenqingfapiao"></i></div>
        <div class="menu-title">我的发票</div>
        <div class="menu-go"><i class="iconfont icon-xiangyoussku"></i></div>
      </div>
    </a>
  </div>
  <div class="login-out" v-if="getLocal('token')">
    <el-button class="login-out-btn" @click="getQuit">退出登录</el-button>
  </div>
  <el-drawer
      v-model="drawer"
      title=""
      direction="btt"
      size="100%"
      :show-close="true"
      :before-close="handleClose"
  >
    <div>
      <div class="drawer-title">请在微信中打开链接</div>
      <p class="drawer-url">
        {{link}}
      </p>
    </div>
    <div class="drawer-btn-rq">
      <div>
        <el-button type="success" class="drawer-btn" size="large"  @click="copyLink">复制链接并前往微信打开</el-button>
      </div>
      <div>
        <el-button type="info" class="drawer-btn" size="large" @click="drawer=false">取消</el-button>
      </div>
    </div>
  </el-drawer>
  <Footers></Footers>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { ElMessage } from 'element-plus'
import { UserFilled } from '@element-plus/icons-vue';
import { postWapwechaturls,postWapwechatLogin } from '@/src/api/apiconfig/main'
import {getLocal, setLocalLogin,removeLocal} from "@/src/utils/local";
import Footers from "@/src/components/wap/footer/index.vue";
const wechatConfig = ref([]);
const appId = 'wxf97d1528bdac9f6e'; // 替换为您的 AppID
const drawer = ref(false)
const redirectUri = encodeURIComponent('http://web.weipark.club/mycenter'); // 替换为您的回调 URL
const link = 'http://web.weipark.club/mycenter'; // 替换为您要复制的链接
const router = useRouter();
const userinfo = reactive({
  userid:'',
  group_id:'',
})

useHead({
  title:'个人中心',
  meta:[
    {hid:'keywords',name:'keywords','content':'个人中心'},
    {hid:'description',name:'description','content':'个人中心'},
  ]
})

const copyLink = async () => {
  try {
    await navigator.clipboard.writeText(link); // 复制链接到剪贴板
    ElMessage({
      message: '链接已复制',
      type: 'success',
    })
  } catch (err) {
    ElMessage.error('复制链接失败，请手动复制。')
  }
};

const wechatLogin = async () => {

  if(isWeChat()){
    wechatConfig.value = await postWapwechaturls();
    const scope = 'snsapi_userinfo'; // 获取用户信息
    const authUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}#wechat_redirect`;
    window.location.href = authUrl; // 重定向到微信授权页面
  }else{
    drawer.value = true;
  }
};
if(process.client){
  // 获取 URL 中的 code
  const urlParams = new URLSearchParams(window.location.search);
  if(!getLocal("token")){
    const code = urlParams.get('code');
    if (code) {
      const response =  await postWapwechatLogin({code:code})
      setLocalLogin('uid',response.uid);
      setLocalLogin("token", response.token)
      setLocalLogin('userid',response.userid);
      setLocalLogin('group_id',response.group_id);
      router.push('/mycenter')
    }
  }
}

const isWeChat = (): boolean => {
  if (typeof window !== 'undefined') {
    return /MicroMessenger/i.test(window.navigator.userAgent);
  }
  return false;
};

 //退出登录
const getQuit = (()=>{

  if(!getLocal("token")){
    drawer.value = true;
  }else{

    removeLocal("token");
    removeLocal("uid");
    router.push('/')
  }
});

</script>

<style scoped>
.avater-rq{
  width: 100%;
  height:50px;
  display: flex;
  padding: 20px;
  align-items: center;
  background: linear-gradient(to right, #f5f3e8 0%, #e67a0b 100%);
}
.login-btn{
  margin-left: 20px;
}
.user-rq{
  margin-left: 20px;
}
.nickname{
  margin-bottom: 5px;
  font-weight: 600;
}
.user-group{
  background: #eee;
  padding: 2px 5px;
  border-radius: 3px;
  width: 64px;
}
.mycenter-for-data{
  width: 100%;
  display: flex;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.menu-icon{
  width:5%;
  text-align: right;
  padding-left: 5px;
}
.menu-title{
  width: 90%;
  padding-left: 10px;
}
.menu-go{
  width: 5%;
}
.drawer-btn-rq{
  width: 100%;
  margin: 0 auto;
  position: absolute;
  bottom: 10px;
  left: 0;
}
.drawer-btn{
  width: 100%;
  margin-top: 20px;
}
.drawer-title{
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: .5rem;
}
.drawer-url{
  text-align: center;
  padding: 50px 20px;
  background: #e7e3e3;
  font-weight: 700;
}

.login-out{
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;

}
.login-out-btn{
  width: 100%;
  height: 40px;
}
</style>